import { Button } from "reactstrap";
import React from "react";
import { CSSTransition } from "react-transition-group";
import useApplyContextElement from "../../hooks/useApplyContext";

type Props = {
  children?: Object;
  context: string;
  onNext?: () => void;
  onNextTitle?: string;
  onBack?: () => void;
  title: string;
  description?: string | Element;
  visible: boolean;
};

const KFPage = (props: Props) => {
  const { contextDict } = useApplyContextElement(props.context, {
    visible: props.visible
  });
  const context = contextDict;

  return (
    <CSSTransition
      in={!(context && context.visible === false)}
      timeout={500}
      classNames="kfpage"
      appear
    >
      <div className="kfpage">
        {props.title.length > 0 ? <h1>{props.title}</h1> : null}
        {props.description ? <p>{props.description}</p> : null}
        {props.children}
        {props.onBack || props.onNext ? <br /> : null}
        {props.onBack ? (
          <Button onClick={props.onBack}> Back </Button>
        ) : null}{" "}
        {props.onNext ? (
          <Button onClick={props.onNext} color="primary">
            {props.onNextTitle ? props.onNextTitle : "Continue"}{" "}
          </Button>
        ) : null}
      </div>
    </CSSTransition>
  );
};

export default KFPage;
