import { Input } from "reactstrap";
import React from "react";
import useApplyContextElement from "../../hooks/useApplyContext";

type Props = {
  question?: string;
  isTextArea?: boolean;
  context: string;
  onValidate?: () => void;
  inline?: boolean;
};
const KFInput = (props: Props) => {
  const { contextDict, setValue } = useApplyContextElement(props.context);
  const context = contextDict;
  const [wordCount, setWordCount] = React.useState(
    ((context && context.value) || "").length
  );
  return (
    <div
      className={
        (context && context.visible === false ? "hidden" : "") +
        " element " +
        (props.inline ? "inline" : "")
      }
    >
      <p>{props.question}</p>
      <Input
        type={props.isTextArea === true ? "textarea" : "text"}
        value={(context && context.value) || ""}
        onChange={e => {
          setWordCount(e.currentTarget.value.length);
          setValue(e.currentTarget.value);
          props.onValidate && props.onValidate();
        }}
      />
      <p>Character count: {wordCount}</p>
    </div>
  );
};

export default KFInput;
