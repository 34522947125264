import { Input, FormGroup, Label } from "reactstrap";
import React from "react";
import useApplyContextElement from "../../hooks/useApplyContext";

type Tuple = {
  value: string;
  description: string;
};
type Props = {
  onChange?: (value: string) => boolean | void;
  options: Tuple[];
  description?: string;
  context: string;
  shouldShow?: (show: boolean) => void;
};
const KFSelect = (props: Props) => {
  const [invalid, setInvalid] = React.useState(false);
  const { contextDict, setValue } = useApplyContextElement(props.context);
  const context = contextDict;

  const options = [
    <option key="" value="">
      --Select--
    </option>
  ].concat(
    props.options.map(({ value, description }: Tuple) => {
      return (
        <option key={value} value={value}>
          {description}
        </option>
      );
    })
  );
  return (
    <FormGroup>
      <Label for={props.context}>{props.description}</Label>
      <Input
        type="select"
        name={props.context}
        id={props.context}
        invalid={invalid}
        value={context && context.value}
        onChange={value => {
          // Set it first immediately before setValue takes effect
          context.value = value.currentTarget.value;
          setValue(value.currentTarget.value);
          if (props.onChange) {
            const validated = props.onChange(value.currentTarget.value);
            if (validated != null) {
              setInvalid(!validated);
            }
          }
        }}
      >
        {options}
      </Input>
    </FormGroup>
  );
};

export default KFSelect;
