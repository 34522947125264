import React from "react";

import {
  ApplyContext,
  ContextType,
  ContextTypeItem
} from "./../components/Apply";

const useApplyContextElement = (context: string, options?: ContextTypeItem) => {
  const [applycontext, setApplyContext] = React.useContext(ApplyContext);
  const contextDict = applycontext[context];
  if (contextDict == null) {
    setApplyContext(applycontext => ({
      ...applycontext,
      [context]: {
        visible: options ? options.visible : true,
        value: options && options.value != null ? options.value : ""
      }
    }));
  }
  const setValue = (value: string) => {
    setApplyContext(applycontext => ({
      ...applycontext,
      [context]: {
        ...applycontext[context],
        value: value
      }
    }));
  };

  return { contextDict, setValue };
};

export const useApplyContextEditor = () => {
  const [applycontext, setApplyContext] = React.useContext(ApplyContext);

  // Returns value or ""
  const getValue = React.useCallback(
    (context: string): string => {
      return (applycontext[context] && applycontext[context].value) || "";
    },
    [applycontext]
  );
  const getValuesEqual = React.useCallback(
    (context: string, context2: string): boolean => {
      return getValue(context) === getValue(context2);
    },
    [getValue]
  );
  const getAllValues = () => {
    return Object.keys(applycontext);
  };
  const setProgress = React.useCallback(
    (progress: string) => {
      setApplyContext((applycontext: ContextType) => ({
        ...applycontext,
        REACT_PROGRESS: { visible: false, value: progress }
      }));
    },
    [setApplyContext]
  );
  const setValue = React.useCallback(
    (context: string, value: string) => {
      setApplyContext((applycontext: ContextType) => ({
        ...applycontext,
        [context]: { ...applycontext[context], value: value }
      }));
    },
    [setApplyContext]
  );
  const setVisibility = React.useCallback(
    (context: string, isVisible: boolean) => {
      setApplyContext((applycontext: ContextType) => ({
        ...applycontext,
        [context]: { ...applycontext[context], visible: isVisible }
      }));
    },
    [setApplyContext]
  );
  const setValidated = React.useCallback(
    (context: string, isValidated: boolean) => {
      setApplyContext((applycontext: ContextType) => ({
        ...applycontext,
        [context]: { ...applycontext[context], validated: isValidated }
      }));
    },
    [setApplyContext]
  );
  const saveApplication = React.useCallback(
    (restart?: boolean): boolean => {
      if (restart === true) {
        if (
          window.confirm("are you sure you want to restart your application?")
        ) {
          setApplyContext({});
          window.localStorage.setItem("application", JSON.stringify({}));
          return true;
        }
      } else {
        window.localStorage.setItem(
          "application",
          JSON.stringify(applycontext)
        );
        return true;
      }
      return false;
    },
    [applycontext, setApplyContext]
  );
  return {
    setProgress,
    setVisibility,
    setValue,
    setValidated,
    saveApplication,
    getValue,
    getValuesEqual,
    getAllValues
  };
};

export default useApplyContextElement;
